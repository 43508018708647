import React from 'react';

export default function Contact() {
  return (
    <section>
      <article>
        <p>
          Contact
        </p>
      </article>
    </section>
  )
};
