import React from 'react';

import { data } from '../data';

import './section.scss';

export default  function About() {
  const about = data.about;

  return (
    <section className="section">
      <h2 className="section__heading">
        { about.heading }
      </h2>
      {/* <img
        src={ require(`../images/${about.image.name}.png`) }
        alt={ about.image.alt }
        className="section__image"
       /> */}
    </section>
  )
};
