import React from 'react';

import About from './about';
import Pictures from './pictures';
import Contact from './contact';

export default function SectionComponent({ name }) {
  const sections = {
    about: About,
    pictures: Pictures,
    contact: Contact,
  };

  const SectionName = sections[name];

  return(
    <SectionName />
  )
};
