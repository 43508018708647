import React from 'react';
import { createPortal } from 'react-dom';

import './overlay.scss';
import './overlay-transition.scss';

import Sidebar from './sidebar';

export default function Overlay({ children, onClose, open, heading }) {
  return open
    ? createPortal(
      <div className="overlay">
        <button
          className="overlay__close-button" type="button"
          onClick={ onClose }
        >
          <svg className="overlay__close-button-svg">
            <g>
              <circle cx="32" cy="32" r="30"/>
              <path d="M21.707 20.293l9.899 9.899 9.9-9.9a1 1 0 111.414 1.415l-9.899 9.899 9.9 9.9a1 1 0 11-1.415 1.414l-9.9-9.899-9.899 9.9a1 1 0 01-1.414-1.415l9.899-9.9-9.9-9.899a1 1 0 011.415-1.414z" fill="#ffffff"/>
            </g>
          </svg>
        </button>
        <Sidebar heading={ heading } />
        { children }
      </div>,
      document.body
    )
  : null;
}
