import React from 'react'

import Moniker from './moniker';

import './sidebar.scss';

export default function OverlaySidebar({ heading }) {
  const handleClick = () => {
    console.log('clickerty');
  }

  return(
    <div className="sidebar">
      <div className="sidebar__body">
        <h1 className="sidebar__heading">{ heading }</h1>
        <Moniker small onClick={ () => handleClick() } />
      </div>
    </div>
  )
}
