export const data = {
  firstName: 'Paul',
  lastName: 'Brislane',
  about: {
    heading:
      'Something coming soon...maybe',
    image: {
      name: 'about-image',
      alt: 'Me in Scotland',
    },
  },
};
