import React from 'react';

import { data } from '../data';

export default function Moniker({ small, onClick  }) {
  return (
    <h1
      className={`moniker ${ small ? 'moniker--sidebar' : ''}`}
      onClick={ onClick }
    >
      {data.firstName}<span>{data.lastName}</span>
    </h1>
  );
}
