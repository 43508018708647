import React from 'react';

export default  function Pics() {
  return (
    <section>
      <article>
        <p>
          Pics
        </p>
      </article>
    </section>
  )
};
