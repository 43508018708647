import React, { useEffect } from 'react';


import Progress from './components/progress';
import Cube from './components/cube';

import './App.scss';

function App() {
  useEffect(() => {
    const root = document.body.style;

    root.setProperty(
      '--scroll',
      window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
    );

    const handleScroll = () => {
      root.setProperty(
        '--scroll',
        window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
      );
    };

    window.addEventListener('scroll', handleScroll, false);
  });

  return (
    <>
      <Progress />
      <Cube />
    </>
  );
}

export default App;
