import React, { useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import CubeButton from './cube_button';
import Moniker from './moniker';
import Overlay from './overlay';

export default function CubeSide({ name, children, side }) {
  const [open, setOpen] = useState(false);

  const toggleOverlay = () => setOpen(!open);

  return (
    <>
      <div className={`cube__side cube__side--${ side }`}>
        { side === 'front' ? (
          <CSSTransition
            appear
            in
            classNames="moniker"
            timeout={5000}
          >
            <Moniker />
          </CSSTransition>
        ) : (
          <CubeButton
            onClick={ () => toggleOverlay() }
            text={ name }
          />
        ) }
      </div>
      <SwitchTransition>
        <CSSTransition
          key={ open }
          classNames="overlay-transition"
          timeout={ 2000 }
        >
          <Overlay
            heading={ name }
            open={ open }
            onClose={ () => setOpen(false) }
            children={ children }
          />
        </CSSTransition>
      </SwitchTransition>
    </>
  );
}
