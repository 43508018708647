import React from 'react';

export default function CubeButton({ onClick, text }) {
  return (
    <button
      className="cube__button"
      onClick={ onClick }
      type="button"
    >
      { text }
    </button>
  )
}
