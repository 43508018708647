import React, { Fragment } from 'react';

import CubeSide from './cube_side';
import SectionComponent from './section-component';

const cubeData = [
  {
    side: 'front',
    name: '',
  },
  {
    side: 'bottom',
		name: 'About',
  },
  {
    side: 'back',
    name: 'Pictures',
  },
  {
    side: 'top',
    name: 'Contact',
  },
];

export default function Cube() {
  return (
    <nav className="cube" aria-labelledby="main navigation">
      <div className="cube__wrapper">
        {cubeData.map(cube => (
          <Fragment key={cube.side} >
            <CubeSide
              name={ cube.name }
              side={ cube.side }
            >
              <SectionComponent name={ `${cube.name}`.toLowerCase() } />
            </CubeSide>
          </Fragment>
        ))}
      </div>
    </nav>
  );
}
